import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Container, useTheme, useMediaQuery } from '@mui/material';
import { Button } from 'gatsby-theme-material-ui'
import ProductLineGallery from './components/ProductLineGallery'

const PREFIX = 'ProductLine';

const classes = {
  container: `${PREFIX}-container`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  button: `${PREFIX}-button`,
  sidePadding: `${PREFIX}-sidePadding`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    position: 'relative',
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(6, 'auto')
    }
  },

  [`& .${classes.buttonWrapper}`]: {
    textAlign: 'right',
    [theme.breakpoints.up("md")]: {
      position: 'absolute',
      bottom: theme.spacing(3),
      right: theme.spacing(3)
    }
  },

  [`& .${classes.button}`]: {
    marginBottom: theme.spacing(2),
    fontSize: '14px',
    textTransform: 'lowercase',
  },

  [`& .${classes.sidePadding}`]: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 1)
    }
  }
}));

function ProductLine(props) {

  const theme = useTheme()
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <StyledContainer maxWidth="md" disableGutters className={classes.container}>
      <div className={classes.sidePadding}>
        <Typography variant={ matchesDesktop ? "h2" : "h1"} color="primary" component="h2">Our product line</Typography>
        <Typography component="span" color="primary" variant="body1">consists of</Typography>
      </div>
      <ProductLineGallery />
      <div className={classes.buttonWrapper}>
        <Button 
          variant="contained" 
          color={ matchesDesktop ? "secondary" : "primary" } 
          className={classes.button} to="/new-conveyors">
            view catalogue
        </Button>
      </div>
    </StyledContainer>
  );
}

export default ProductLine;