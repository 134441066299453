import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useStaticQuery, graphql } from 'gatsby';
import ProductCard from './ProductCard';

const PREFIX = 'ProductLineGallery';

const classes = {
  wrapper: `${PREFIX}-wrapper`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.wrapper}`]: {
    margin: theme.spacing(2, 0),
    background: '#ffffff80',
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    }
  }
}));

function ProductLineGallery() {

  const theme = useTheme()
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { allWpProductLineGalleryItem: { products }, placeholderImage } = useStaticQuery(graphql`query GetProducts {
  allWpProductLineGalleryItem {
    products: nodes {
      product_line_details {
        productToShow {
          ... on WpGroupProduct {
            id
            name
            shortDescription
            description
            price
            slug
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 551, height: 263, quality: 80, layout: CONSTRAINED)
                }
              }
            }
            productCategories {
              nodes {
                name
                slug
              }
            }
          }
        }
        order
      }
      title
    }
  }
  placeholderImage: file(relativePath: {eq: "woocommerce-placeholder-1024.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, width: 551, height: 263, layout: CONSTRAINED)
    }
  }
}
`)

const sortedProducts = products.sort((product1, product2) => product1.product_line_details.order - product2.product_line_details.order)

  return (
    <Root className={classes.wrapper}>
      <Grid container spacing={1}>
        { !matchesSm &&
            sortedProducts
            .map(({ product_line_details: { productToShow } }, i) => {
              if(!productToShow) return null

              return (
                <Grid key={productToShow.id} item xs={(i + 1) % 3 !== 0 ? 6 : 12}>
                  <ProductCard product={productToShow} placeholderImage={placeholderImage} height={(i + 1) % 3 !== 0 ? 196 : 120 } />
                </Grid>
              )
            })
        }
        {
          matchesSm && (
            <>
              <Grid item sm={5}>
                <Grid container spacing={1}>
                  {
                    sortedProducts.slice(0, 2).map(({ product_line_details: { productToShow } }) => {
                      if(!productToShow) return null
                      return (
                        <Grid key={productToShow.id} item xs={12}>
                          <ProductCard product={productToShow} placeholderImage={placeholderImage} height={263} />
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
              <Grid item sm={7}>
                <Grid container spacing={1}>
                {
                  sortedProducts.slice(2).map(({ product_line_details: { productToShow } }, i) => {
                    if(!productToShow) return null
                      return (
                        <Grid key={productToShow.id} item xs={i < 2 ? 6 : 12}>
                          <ProductCard product={productToShow} placeholderImage={placeholderImage} height={173} />
                        </Grid>
                      )
                  })
                }
                </Grid>
              </Grid>
          </>
          )
        }
      </Grid>
    </Root>
  );
}

export default ProductLineGallery;
