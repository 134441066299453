import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  ButtonBase,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Button } from 'gatsby-theme-material-ui';
import { Close as CloseIcon } from '@mui/icons-material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'


const PREFIX = 'ProductCard';

const classes = {
  background: `${PREFIX}-background`,
  card: `${PREFIX}-card`,
  nameContainer: `${PREFIX}-nameContainer`,
  name: `${PREFIX}-name`
};

const Root = styled('article')((
  {
    theme
  }
) => ({
  [`& .${classes.background}`]: {
    // opacity: '1 !important',
    width: '100%',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },

  [`& .${classes.card}`]: {
    gridArea: "1/1",
    position: "relative",
  // This centers the other elements inside the hero component
    background: `linear-gradient(#00000066, transparent)`,
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    position: 'relative'
  },

  [`& .${classes.nameContainer}`]: {
    position: 'absolute',
    right: 12,
    top: 12,
    left: 12,
    textAlign: 'left'
  },

  [`& .${classes.name}`]: {
    display: 'inline-block',
    color: `${theme.palette.common.white} !important`,
    lineHeight: '115% !important',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  }
}));

const ProductCard = ({ product, placeholderImage, height }) => {

  const [ open, setOpen ] = useState(false)

  const handleCardClick = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const description = product.shortDescription || product.description

  return (
    <Root style={{ display: 'grid'}}>
      <GatsbyImage
        style={{
          gridArea: "1/1",
          height: height
        }}
        layout="fullWidth"
        alt={product.name}
        image={getImage(product.image?.localFile || placeholderImage)}
        formats={["auto", "webp", "avif"]}
      />
      <ButtonBase 
      focusRipple
      onClick={handleCardClick} 
      className={classes.card}
      >
        <div className={classes.nameContainer}>
          <Typography variant="h4" component="h3" className={classes.name}>
            { product.name }
          </Typography>
        </div>
      </ButtonBase>
      <Dialog onClose={handleClose} aria-labelledby="product card details" open={open}>
        <IconButton onClick={handleClose} size="small" style={{ position: 'absolute', top: 5, right: 5 }}>
          <CloseIcon />
        </IconButton>
        <DialogTitle id="customized-dialog-title" component="h3" style={{ marginTop: 20 }}>
          {product.name}
        </DialogTitle>
        <DialogContent dividers>
          <div>
            {
              description ? 
                parse(description) :
                'No description found.'
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button to={`/${product.productCategories?.nodes?.[0]?.slug}/${product.slug}`} color="primary">
            go to catalog
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
}

export default ProductCard;